export default ($) => {

    $(".vehicle-brand-select").on('change', function () {
        const brand = $(this).val();
        const modelSelected = $('.input-vehicle-model').val();
        if (brand) {
            $.getJSON('/vehicle_models/' + brand, function (models) {
                $('.model-select select').html(
                    '<option value="" selected="selected"></option>' + models.map(model =>
                        // Wenn wir schonmal ein Model gewählt hatten, dieses nochmal wählen (Validierung)
                        modelSelected == model ? `<option selected>${model}</option>` : `<option>${model}</option>`
                    ).join('')
                ).removeAttr('disabled').selectpicker('refresh').trigger('change');
            });
        }
    });

    $('.model-select select').on('change', function () {
        $('.input-vehicle-model').val($(this).val());
    });

    // Beim Seitenaufruf ggf. einmal change triggern, falls es einen Wert aus der Validierung gibt
    // console.log("GUER", $("select.vehicle-brand-select").length, $("select.vehicle-brand-select").val())
    // if($("select.vehicle-brand-select").length && $("select.vehicle-brand-select").val()){
    //   console.log("Kommt?")
    //   $(".vehicle-brand-select").trigger('change');
    // }

} 