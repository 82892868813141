require('es6-promise').polyfill();
require('isomorphic-fetch');
import "@babel/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import Suche from './Suche';
import DatenAendern from './DatenAendern';
import EintragungBeantragen from './EintragungBeantragen'
import OrderMaterial from './OrderMaterial'



class Haendlercheck extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      changePartner: null,
      showPartnerForm: false,
      orderMaterial: false,
    };
  }

  partnerShowHandler() {
    const { changePartner, showPartnerForm } = this.state;
    this.setState({
      showPartnerForm: !showPartnerForm,
      changePartner: false
    });
  }

  render() {
    const { changePartner, showPartnerForm, orderMaterial } = this.state;

    return (
      <div className=" haendlerdaten_aendern" role="dialog">
        <div className="">
          <div className="">
            <Suche 
              changePartner={partner => this.setState({changePartner: partner, showPartnerForm: false})} 
              orderMaterial={partner => this.setState({orderMaterial: partner})} 
              partnerShowHandler={this.partnerShowHandler.bind(this)}
            />
            
            { !!changePartner && <DatenAendern partner={changePartner} />}

            { !!orderMaterial && <OrderMaterial partner={orderMaterial} />}

            { !!showPartnerForm && <EintragungBeantragen />}
          </div>
        </div>
      </div>
    );
  }
}

let haendlercheckDiv = document.getElementById("haendlercheck");

if(haendlercheckDiv){
  ReactDOM.render(<Haendlercheck />, haendlercheckDiv);
}