import React from 'react';
import { createGraphQlRequest } from '../util/partnerApi';

export default class DatenAendern extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      partner: JSON.parse(JSON.stringify(props.partner)),
      toUpdate: {}
    };
  }

  _updateField(field, value){
    const { partner, toUpdate } = this.state;
    partner[field] = value;
    toUpdate[field] = value;
    this.setState({partner, toUpdate});
  }

  _submitForChange(){
    const { partner, toUpdate } = this.state;

    toUpdate.tireOrigin = toUpdate.provenance ? toUpdate.provenance : toUpdate.provenanceSelect;
    toUpdate.partnerId = partner.id;

    createGraphQlRequest('mutation($input: UpdateRequestInput!){createUpdateRequest(updateRequestInput: $input){created}}', {
      input: toUpdate
    })
    .then(res => {
      if(!res.errors){
        alert('Vielen Dank! Wir werden die Änderung prüfen.')
        window.location.reload();
      }
      console.error(res.errors);
    })
  }

  render(){

    const { partner } = this.state;

    return (
      <div
        className="haendler-details"
      >
        <input type="hidden" className="form-control" name="kundennr" value={partner.kundennr || ""} onChange={e => this._updateField('kundennr', e.target.value)} />
        <div className="bs-input form-group">
          <input
            type="text"
            className={"form-control"+(partner.name.length ? ' active' : '')}
            value={partner.name}
            onChange={e => this._updateField('name', e.target.value)}
            required="required"
          />
          <label>
            {t('haendlercheck.name')}
          </label>
        </div>
        <div className="bs-input form-group">
          <input
            type="text"
            className={"form-control"+(partner.strasse.length ? ' active' : '')}
            value={partner.strasse}
            onChange={e => this._updateField('strasse', e.target.value)}
            required="required"
          />
          <label>
            {t('haendlercheck.strasse_und')}
          </label>
        </div>
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col-sm-4">
            <div className="bs-input form-group">
              <input
                type="text"
                className={"form-control"+(partner.plz.length ? ' active' : '')}
                value={partner.plz}
                onChange={e => this._updateField('plz', e.target.value)}
                required="required"
              />
              <label>
                {t('postalcode')}
              </label>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="bs-input form-group">
              <input
                type="text"
                className={"form-control"+(partner.ort.length ? ' active' : '')}
                value={partner.ort}
                onChange={e => this._updateField('ort', e.target.value)}
                required="required"
              />
              <label>
                {t('city')}
              </label>
            </div>
          </div>
        </div>
        <center style={{ marginTop: 10 }}>
          <button
            className="form-control btn btn-primary"
            type="submit"
            onClick={e => this._submitForChange()}
          >
            {t('haendlercheck.submit')}
          </button>
        </center>
      </div>
    );
  }
}