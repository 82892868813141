require('./print.min');

export default ($) => {
    function cleanData(data) {
        return (data && (data !== 'NULL' || data !== 'null' || data !== 'NaN') ? data : '')
    }
    var refill_once = 0;
    var debounceTimeout = null; // Variable für das Debounce Timeout

    $(".inputtext-haendlersearchval").on("keyup", function () {
        clearTimeout(debounceTimeout); // Bisheriges Timeout löschen
  
        debounceTimeout = setTimeout(() => { // Neues Timeout setzen
        var field = $(this);
        $('.btn-submit').attr('disabled', true);
        if (field.val().length >= 3) {
            var searchval = field.val();
            var land = $("body").attr('data-country');
            // var query = "{partner(aktionsmeldung:\"" + aktionskurzname + "\", plz:\"" + searchval + "\", distance:\"10\", land:\"" + land + "\"){id,distance,name,strasse,plz,ort,land,lat,lng}}";
            if (searchval.length == 4) {
                var searchType = /^\d+$/.test(searchval) === true ? "\", plz:\"" + searchval + "\", distance:\"10\", land:\"" + land + "\"" : "\", search: \"" + searchval + "\"";
                var query = "{partner(aktionsmeldung:\"" + aktionskurzname + searchType + "){ id, distance, name, strasse, plz, ort, land, lat, lng }}";
            } else {
                var searchType = /^\d+$/.test(searchval) === true ? "\", plz:\"" + searchval + "\", land:\"" + land + "\"" : "\", search: \"" + searchval + "\"";
                var query = "{partner(aktionsmeldung:\"" + aktionskurzname + searchType + "){ id, distance, name, strasse, plz, ort, land, lat, lng }}";
            }

            fetch(partner_api, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify({ query: query, variables: {} })
            })
                .then(function (res) { return res.json() })
                .then(function (json) {
                    if (json.errors) {
                        throw json.errors;
                    }
                    $(".haendler-ergebnisse .row").html("");
                    $.each(json.data.partner, function (i, haendler) {
                        $(".haendler-ergebnisse .row").append(renderPartnerResult(haendler));
                    });

                    // Existierenden Händler gewählt
                    $(".haendler-chosen").on("click", function () {
                        $(".haendler-ergebniss").removeClass("active");
                        $(this).addClass("active");
                        $(".haendler-manual-fields").slideUp();
                        $(".haendler-manual-fields input").val("").removeAttr('required');
                        $(".input-partner_id").val($(this).attr("data-partnerid"));

                        $(".haendler-chosen .btn-fullwidth span").html($(this).attr('data-val-initial'));
                        $(this).find("span").html($(this).attr('data-val-selected'));

                        $('.btn-submit').removeAttr('disabled');
                    });

                    if ($(".input-partner_id").val() && $(".input-partner_id").val().length && refill_once == 0 && refill_haendler) {
                        $(".haendler-ergebniss[data-partnerid='" + $(".input-partner_id").val() + "'] .btn").trigger("click");
                        refill_once++;
                    }

                })
                .catch(function (error) {
                    console.error(error);
                    //alert(translations.error)
                    // $.post('/log_frontend_exception', {error: JSON.stringify(error)});
                })
            }
        }, 800); // 800 ms Debounce-Zeit
    });

    function toggleModal() {
        $("#haendlersuche-modal")
            .modal('toggle')
            .find('.inputtext-haendlersearchval')
            .val(
                $('.widget--haendlersearch .inputtext-haendlersearchval').val()
            ).trigger('change').focus();
    }

    $('.widget--haendlersearch .haendler-ergebnisse').on('mousedown',
        function () {
            if ($(".haendler-ergebnisse").text().trim().length > 0) {

                toggleModal();
            }
        });
    $('.widget--haendlersearch .inputtext-haendlersearchval').on('keypress', function (e) {
        if ($(".haendler-ergebnisse").text().trim().length > 0 && e.which == 13) {
            toggleModal();
        }
    });

    $('#haendlersuche-modal').on('hidden.bs.modal', function () {
        $('.widget--haendlersearch .inputtext-haendlersearchval')
            .val(
                $('#haendlersuche-modal .inputtext-haendlersearchval').val()
            ).trigger('change').focus();
    });

    $("#haendlersuche-modal .btn-print").on('click', function () {
        printJS({
            printable: 'haendlersuche-modal-content',
            type: 'html',
            css: '/css/print.css'
        });
    });

    if ($(".inputtext-haendlersearchval").length && $(".inputtext-haendlersearchval").val().length > 2) {
        $(".inputtext-haendlersearchval").trigger('keyup');
    }
} 