import React from 'react';
import { createGraphQlRequest } from '../util/partnerApi';

export default class EintragungBeantragen extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      partner: {
        "kundennr": "",
        "name": "",
        "strasse": "",
        "plz": "",
        "ort": "",
        "email": "",
        "land": "de",
      },
      partnerCreate: {
        "kundennr": "",
        "name": "",
        "strasse": "",
        "plz": "",
        "ort": "",
        "email": "",
        "land": "de",
      },
      isSubmitting: false,
      valid: false
    };
    this._submitForCreation = this._submitForCreation.bind(this);
  }

  _updateField(field, value){
    const { partner, partnerCreate } = this.state;
    partner[field] = value;
    if (field != 'otherOrigin') {
      partnerCreate[field] = value;
    }
    this.setState({partner, partnerCreate});
  }

  _submitForCreation(e){

    if (this.state.isSubmitting) {
        alert(t('haendlercheck.in_progress'));
        return;
    }

    this.setState({ isSubmitting: true });

    e.preventDefault();

    var validator = [];
    for (let key in this.state.partnerCreate) {
      if(this.state.partnerCreate.hasOwnProperty(key) && !!key){
        validator.push(this.state.partnerCreate[key]);
      }
    }
    function isValid(input){
      return input.length > 0;
    }

    if(validator.every(isValid)){
      createGraphQlRequest("mutation createPartner($partner: PartnerInput!) {createPartner(partner: $partner) {id,kundennr,name,strasse,plz,email,ort,land}}", {
        "partner": this.state.partnerCreate
      })
      .then(res => {
        if(!res.errors){

          let partnerId = res.data.createPartner.id;
          let aktionId = window.aktionid;
        
            createGraphQlRequest('mutation createActivationRequest($input: ActivationRequestInput!){createActivationRequest(activationRequestInput: $input){created, aktion {kurzName}}}', {
              input: {
                partnerId: parseInt(partnerId),
                aktionId: aktionId
              }
            })
            .then(res => {
              alert(t('haendlercheck.success'));
              window.location.reload();
            }).catch(error => {
                console.error(error);
                this.setState({ isSubmitting: false });
            });
        }else {
            this.setState({ isSubmitting: false });
        }
      }).catch(error => {
                console.error(error);
                this.setState({ isSubmitting: false });
            });
    }else{
      alert(t('haendlercheck.fill_fields'))
      this.setState({ isSubmitting: false });
    }
  }

  render(){
    return (
      <div className="create-partner-form">
        <div className="bs-input form-group">
          <input
            type="text"
            className={"form-control " + (this.state.partner.kundennr.length > 0 ? "active" : "")}
            name="partner[Kundennr]"
            defaultValue=""
            onChange={e => this._updateField('kundennr', e.target.value)}
          />
          <label>
            {t('haendlercheck.kdrn_l')}
          </label>
        </div>
        <div className="bs-input form-group">
          <input
            type="text"
            className={"form-control " + (this.state.partner.name.length > 0 ? "active" : "")}
            name="partner[Name]"
            defaultValue=""
            required="required"
            onChange={e => this._updateField('name', e.target.value)}
          />
          <label>
            {t('haendlercheck.name')}
          </label>
        </div>
        <div className="bs-input form-group">
          <input
            type="text"
            className={"form-control " + (this.state.partner.strasse.length > 0 ? "active" : "")}
            name="partner[Strasse]"
            defaultValue=""
            required="required"
            onChange={e => this._updateField('strasse', e.target.value)}
          />
          <label>
            {t('haendlercheck.strasse_und')}
          </label>
        </div>
        <div className="row location-details">
          <div className="col-sm-4">
            <div className="bs-input form-group">
              <input
                type="text"
                className={"form-control " + (this.state.partner.plz.length > 0 ? "active" : "")}
                name="partner[plz]"
                defaultValue=""
                required="required"
                onChange={e => this._updateField('plz', e.target.value)}
              />
              <label>
                {t('postalcode')}
              </label>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="bs-input form-group">
              <input
                type="text"
                className={"form-control " + (this.state.partner.ort.length > 0 ? "active" : "")}
                name="partner[ort]"
                defaultValue=""
                required="required"
                onChange={e => this._updateField('ort', e.target.value)}
              />
              <label htmlFor="partner[ort]">
                {t('city')}
              </label>
            </div>
          </div>
        </div>
        <div className="bs-input form-group">
          <input
            type="text"
            className={"form-control " + (this.state.partner.email.length > 0 ? "active" : "")}
            name="partner[Email]"
            defaultValue=""
            onChange={e => this._updateField('email', e.target.value)}
          />
          <label htmlFor="partner[Email]">Email</label>
        </div>
        <br />
        <center>
          <button 
            className="form-control btn btn-primary" 
            onClick={this._submitForCreation}
          >
            {t('haendlercheck.eintragung_beantragen')}
          </button>
        </center>
      </div>
    )
  }
}