import React, { useState } from 'react';
import { createGraphQlRequest } from '../util/partnerApi';
import EintragungBeantragen from './EintragungBeantragen'
var debounceTimeout = null; // Variable für das Debounce Timeout

export default class Suche extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      plz: '',
      partners: undefined,
      actionsTaken: []
    };

    this._createActivationRequest = this._createActivationRequest.bind(this);
  }

  changePlz(plz) {
    this.setState({ plz });
    var land = document.body.dataset.country;
    clearTimeout(debounceTimeout); // Bisheriges Timeout löschen
    debounceTimeout = setTimeout(() => { // Neues Timeout setzen
        if (plz.length >= 3) {
        createGraphQlRequest('{partner(plz: "' + plz + '", aktionsmeldung:"'+aktionskurzname+'", land:"'+land+'"){id,kundennr, name,strasse,plz,ort,land, aktionsmeldungen {type,anzahl,aktion {kurzName}}, materialRequests{materialType, aktion{kurzName}}, activationRequests {aktion {kurzName}} }}')
            .then(res => {
            this.setState({ partners: res.data.partner });
            })
        }
    }, 800); // 800 ms Debounce-Zeit
  }

  _createMaterialRequest(partnerId, materialType) {
    const { plz, partners, actionsTaken } = this.state;

    this.setState({
      actionsTaken: [...actionsTaken, partnerId+materialType]
    })

    createGraphQlRequest('mutation ($input: MaterialRequestInput!){createMaterialRequest(materialRequestInput: $input){created, aktion {kurzName}}}', {
      input: {
        partnerId: parseInt(partnerId),
        aktionId: window.aktionid,
        materialType: materialType
      }
    })
      .then(res => {
        if (!res.errors) {
          partners.find(partner => partner.id == partnerId).materialRequests.push(res.data.createMaterialRequest);
          this.setState({ partners });
          alert('Vielen Dank für Ihre Material-Anfrage. Diese werden wir innerhalb von 10 Werktagen ausliefern!')
        } else {
          alert('Ein Fehler ist aufgetreten, bitte kontaktieren Sie die Hotline');
        }
      })
  }

  _createActivationRequest(partnerId) {
    const { plz, partners } = this.state;

    createGraphQlRequest('mutation ($input: ActivationRequestInput!){createActivationRequest(activationRequestInput: $input){created, aktion {kurzName}}}', {
      input: {
        partnerId: parseInt(partnerId),
        aktionId: window.aktionid
      }
    })
      .then(res => {
        if (!res.errors) {
          partners.find(partner => partner.id == partnerId).activationRequests.push(res.data.createActivationRequest);
          this.setState({ partners });
        } else {
          alert('Ein Fehler ist aufgetreten, bitte kontaktieren Sie die Hotline');
        }
      })
  }

  render() {
    const { changePartner, partnerShowHandler, orderMaterial } = this.props;
    const { plz, partners, actionsTaken } = this.state;
    return (
      <div>
        <div className="modal-header">
          <h4 className="modal-title" id="intro-head">
            {t('haendlercheck.intro')}
          </h4>
          <h4
            className="modal-title"
            id="eintragung-head"
            style={{ display: "none" }}
          >
            {t('haendlercheck.eintragung_intro')}
          </h4>
          <h4
            className="modal-title"
            id="aendern-head"
            style={{ display: "none" }}
          >
            {t('haendlercheck.aenderung_intro')}
          </h4>
        </div>

        <div className="modal-body">
          <input
            type="text"
            className="form-control"
            name="plz_search"
            autoComplete="false"
            placeholder={t('postalcode')}
            value={plz}
            onChange={e => this.changePlz(e.target.value)}
          />

          {!!partners && (
            <div
              className="haendler-select"
              style={{
                padding: "20px 0px",
                display: "block",
                overflow: "auto"
              }}
            >
              <table className="table table-condensed table-striped scroll">
                <thead>
                  <tr>
                    {/* <th>
                      {t('haendlercheck.kdrn')}
                    </th> */}
                    <th>
                      {t('haendlercheck.name')}
                    </th>
                    <th>
                      {t('haendlercheck.strasse')}
                    </th>
                    <th>
                      {t('postalcode')}
                    </th>
                    <th>
                      {t('city')}
                    </th>
                    <th>
                      {t('haendlercheck.choose')}
                    </th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "0.8em" }}>
                  {partners.map((partner, i) => (
                    <tr key={i}>
                      {/* <td>{partner.kundennr}</td> */}
                      <td>{partner.name}</td>
                      <td>{partner.strasse}</td>
                      <td>{partner.plz}</td>
                      <td>{partner.ort}</td>
                      <td>
                        <button className="btn btn-xs" onClick={() => { changePartner(partner) }}>
                          {t('haendlercheck.change_data')}
                        </button>{" "}
                        <a className="btn btn-xs" href="#form" onClick={() => { orderMaterial(partner) }}>
                        {t('haendlercheck.order_material')}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <center>
                <button className="btn btn-xs btn-haendler-hinzufuegen" onClick={(e) => partnerShowHandler()}>
                  {t('haendlercheck.nicht_dabei_beantragen')}
                </button>
              </center>

            </div>
          )}
        </div>
      </div>
    )
  }
}