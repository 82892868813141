const $ = require('jquery');
require('bootstrap');
require('bootstrap-select');
import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-da';
import '../scss/app.scss';
import haendlersuche from './haendlersuche';
import vehicle_select from './vehicle_select';
import haendlercheck from './Haendlercheck';

$(function () {
    datepickerFactory($);
    datepickerJAFactory($);
    function createDatepicker(target) {
        $(target).datepicker({
            dateFormat: "dd.mm.yy",
            prevText: '&#12296;',
            nextText: '&#12297;',
            maxDate: new Date(),
            formatter: (input, date, instance) => {
                //console.log(date.getDate());
                day = date.getDate();
                month = date.getMonth();
                month = month + 1;
                if ((String(day)).length == 1)
                    day = '0' + day;
                if ((String(month)).length == 1)
                    month = '0' + month;
                input.value = day + '.' + month + '.' + date.getFullYear();
            },
            monthNames: window.translations.monthNames,
            monthNamesShort: window.translations.monthNamesShort,
            dayNames: window.translations.dayNames,
            dayNamesShort: window.translations.dayNamesShort,
            dayNamesMin: window.translations.dayNamesMin,
        })
    }
    createDatepicker('#invoice_invoiceDate')
    createDatepicker('#claim_damageDate')

    /* add- ist gefolgt von tire, invoice, file.
       Im data-type des geklickten Elementes findet sich: tire, invoice, file
    */
    $("body").on('click', 'div[class^="add-"]', function (e) {
        var rowCounter = $('.' + $(this).attr('data-type') + '-container').children().length;
        /* Inhalt des Prototypes holen */
        var newRow = $('.prototype-' + $(this).attr('data-type') + '-helper').attr('data-prototype');
        newRow = newRow.replace(/__name__/g, rowCounter);
        /* neue Row einfügen */
        $('.' + $(this).attr('data-type') + '-container').append(newRow);
        $('select', $('.' + $(this).attr('data-type') + '-container .' + $(this).attr('data-type') + '-item').last()).val('');
        $('input', $('.' + $(this).attr('data-type') + '-container .' + $(this).attr('data-type') + '-item').last()).val('');
        createDatepicker('body .datepicker_' + rowCounter + '_');
        $('.selectpicker', $('.' + $(this).attr('data-type') + '-container .tire-item').last()).selectpicker('refresh');
        $('.selectpicker', $('.' + $(this).attr('data-type') + '-container .tire-item').last()).selectpicker('render');
        $('.custom-' + $(this).attr('data-type') + ' label').attr('data-after', window.translations.choose_file);
        if ($('.' + $(this).attr('data-type') + '-item').length >= 2) {
            $('.del-' + $(this).attr('data-type')).removeClass('d-none');
        }
    })
    $("body").on('change', '#claim_warranty', function (e) {
        if ($(this).val() > 0) {
          $('#claim_driver_address_first_name').addClass('.form-control active').val(drivers[$(this).val()].firstname);
          $('#claim_driver_address_last_name').addClass('.form-control active').val(drivers[$(this).val()].lastname);
          if (drivers[$(this).val()].street != null && drivers[$(this).val()].street != '') {
            $('#claim_driver_address_street').addClass('.form-control active').val(drivers[$(this).val()].street);
          }
          if (drivers[$(this).val()].phone != null && drivers[$(this).val()].phone != '') {
            $('#claim_driver_address_phone').addClass('.form-control active').val(drivers[$(this).val()].phone);
          }
          if (drivers[$(this).val()].postalcode != null && drivers[$(this).val()].postalcode != '') {
            $('#claim_driver_address_postal_code').addClass('.form-control active').val(drivers[$(this).val()].postalcode);
          }
          if (drivers[$(this).val()].city != null && drivers[$(this).val()].city != ''){
            $('#claim_driver_address_city').addClass('.form-control active').val(drivers[$(this).val()].city);
          }
        }
      });
})


window.translations = {
    'click_to_choose': 'übernehmen',
    'chosen': 'ausgewählt',
}

$(document).ready(function () {
    $('[data-toggle="popover"]').popover();

    $('.collapse').on('show.bs.collapse hide.bs.collapse', function(e) {
        e.preventDefault();
    });

    $('[data-toggle="collapse"]').on('click', function(e) {
        e.preventDefault();
        $($(this).data('target')).toggleClass('show');
        if($($(this).data('target')).hasClass('warranty')){
            $($(this).data('target')).find('iframe').each(function(){
                $(this).attr('src', $(this).attr('data-src'));
            });
        }
    });

    $('.selectpicker').selectpicker({
        noneSelectedText : ''
    });
    haendlersuche($);
    vehicle_select($);

    $(".custom-file-input").on('change', function(e){
        if($(this)[0].files.length){
            $(this).parent().find('.custom-file-label').html(
                $(this)[0].files.length ? $(this)[0].files[0].name : ''
            );
            $(this).closest('.vich-file').addClass('active');
        }
    });

    $('input, textarea').on('change', function(){
        if($(this).val()){
            $(this).addClass('active');
        }else{
            $(this).removeClass('active');
        }
    }).trigger('change');

    $('select').on('change click', function(){
        if($(this).val()){
            $(this).addClass('active');
            $(this).closest('.dropdown').addClass('active');
        }else{
            $(this).removeClass('active');
            $(this).closest('.dropdown').removeClass('active');
        }
    }).trigger('change');



    // function checkHeader() {
    //     if ($(window).scrollTop() > 50) {
    //         $('.nav-head').addClass('scrolling__down')
    //     } else {
    //         $('.nav-head').removeClass('scrolling__down')
    //     }
    // }
    
    // $(window).scroll(function () {
    //     checkHeader()
    // });
    // checkHeader()

    $('.hotline-collapse').on("click", function (e) {
        $(this).addClass('d-none');
        $('.hotline-details').removeClass('d-none');
        e.stopPropagation();
    });
    if ($(window).scrollTop() < 50) {
        $(document).on("click", function (e) {
            if ($('.hotline-collapse').hasClass('d-none')) {
                $('.hotline-collapse').removeClass('d-none');
                $('.hotline-details').addClass('d-none');
            }
            // else (
            //     $('.hotline-collapse').on("click", function(e){
            //         $(this).addClass('d-none');
            //         $('.hotline-details').removeClass('d-none');
            //     })
            // )
        })
    }
});