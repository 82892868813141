const headers = {
  "Content-Type": "application/json", 
  "Accept": "application/json",
};

const URL = 'https://partner.test-our-best.eu/graphql';

const createGraphQlRequest = (query, variables = {}) => {
  return fetch(URL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ query, variables:  variables})
  }).then((res) => res.json());
}

module.exports = {
  createGraphQlRequest: createGraphQlRequest,

  userData: (user, key) => {
      const dataObj = user.data.find((d) => d.key === key)
      return dataObj ? dataObj.value : "";
  }
}