import React from 'react';
import { createGraphQlRequest } from '../util/partnerApi';

const availableMaterials = {
  'de': [
    {key: 'POS-Paket'},
    {key: 'Flyer'},
  ],
  'at': [
    {key: 'POS-Paket'},
    {key: 'Flyer'},
  ],
  'ch': [
    {key: 'POS-Paket'},
    {key: 'Flyer'},
    {key: 'POS-Paket-FR'},
    {key: 'Flyer-FR'},
    {key: 'POS-Paket-IT'},
    {key: 'Flyer-IT'},
  ],
  'dk': [
    { key: 'pos_paket' },
    { key: 'flyer' },
  ]
}

const localeToCountry = {
  'de': 'de',
  'at': 'at',
  'ch': 'ch',
  'fr': 'ch',
  'it': 'ch',
  'dk': 'dk'
}

export default class OrderMaterial extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      partner: JSON.parse(JSON.stringify(props.partner)),
      toOrder: [],
      saving: false,
      done: false,
    };
  }

  _submitOrders(){
    const { partner, toOrder } = this.state;

    if(!toOrder.length){
      return;
    }

    this.setState({
      saving: true
    });

    const promises = toOrder.map(materialType => {
      return createGraphQlRequest('mutation ($input: MaterialRequestInput!){createMaterialRequest(materialRequestInput: $input){created, aktion {kurzName}}}', {
        input: {
          partnerId: parseInt(partner.id),
          aktionId: window.aktionid,
          materialType: materialType
        }
      })
    })

    Promise.all(promises).then(() => {
      this.setState({
        saving: false,
        done: true
      })
    }).catch(e => {
      this.setState({
        saving: false
      })
      alert(t('haendlercheck.unknown_error'));
    })
    
  };

  _onChangeMaterial (e) {
    let { toOrder } = this.state;
    if(e.target.checked){
      toOrder.push(e.target.value)
    }else{
      toOrder = toOrder.filter(el => el !== e.target.value);
    }
    this.setState({
      toOrder
    })
  }

  render(){

    const { partner, toOrder, saving, done } = this.state;

    const materials = availableMaterials[
      partner.land ? partner.land : localeToCountry[window.locale]
    ];

    if(saving){
      return <p className="feedback">{t('haendlercheck.saving')}</p>;
    }

    if(done){
      return <p className="feedback">{t('haendlercheck.material_ordered')}</p>
    }

    return (
      <div
        className="haendler-details"
      >
        
        <h2>{t('haendlercheck.choose_material')}</h2>
        
        <div className="materialselection">
          { materials.map(m => {

            return (
              <div className="checkbox" key={m.key}>
                <label>
                  <input type="checkbox" value={m.key} onChange={this._onChangeMaterial.bind(this)} checked={toOrder.includes(m.key)} />
                  {t('haendlercheck.mat.'+m.key)}
                </label>
              </div>
              
            )
          }) }
        </div>


        <center style={{ marginTop: 10 }}>
          <button
            className="form-control btn btn-primary"
            type="submit"
            onClick={e => this._submitOrders()}
          >
            {t('haendlercheck.order_material')}
          </button>
        </center>
      </div>
    );
  }
}